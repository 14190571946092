// Imports => Dependencies
import React, { createContext } from 'react';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction,
} from 'mobx';

// Imports => API
import createApi from '@api';

// Imports => Stores
import AuthStore from '@stores/auth.store';
import CompaniesStore from '@stores/companies.store';
import ConfigurationsStore from '@stores/configurations.store';
import ContractsStore from '@stores/contracts.store';
import ControlUnitsStore from '@stores/control-units.store';
import ControlUnitTypesStore from '@stores/control-unit-types.store';
import EquipmentStore from '@stores/equipment.store';
import HammersStore from '@stores/hammers.store';
import HammerTypesStore from '@stores/hammer-types.store';
import JeanLutzStore from '@stores/jean-lutz.store';
import PileListStore from '@stores/pilelist.store';
import PowerpacksStore from '@stores/powerpacks.store';
import PowerpackTypesStore from '@stores/powerpack-types.store';
import ProjectsStore from '@stores/projects.store';
import ProfileStore from '@stores/profile.store';
import ReportsStore from '@stores/reports.store';
import ToastersStore from '@stores/toasters.store';
import TransfersStore from '@stores/transfers.store';
import UiStore from '@stores/ui.store';
import UsersStore from '@stores/users.store';
import MyCompanyStore from '@stores/my-company.store';
import ErpStore from '@stores/erp.store';
import { OperationCenterStore } from './operationcenter.store';

class Store {
  constructor(config) {
    makeObservable(this);

    this.config = config;

    this.api = createApi(config);

    this.auth = new AuthStore(this);
    this.companies = new CompaniesStore(this);
    this.configurations = new ConfigurationsStore(this);
    this.contracts = new ContractsStore(this);
    this.control_units = new ControlUnitsStore(this);
    this.control_unit_types = new ControlUnitTypesStore(this);
    this.equipment = new EquipmentStore(this);
    this.erp = new ErpStore(this);
    this.hammers = new HammersStore(this);
    this.hammer_types = new HammerTypesStore(this);
    this.jeanlutz = new JeanLutzStore(this);
    this.myCompany = new MyCompanyStore(this);
    this.pilelist = new PileListStore(this);
    this.powerpacks = new PowerpacksStore(this);
    this.powerpack_types = new PowerpackTypesStore(this);
    this.projects = new ProjectsStore(this);
    this.profile = new ProfileStore(this);
    this.reports = new ReportsStore(this);
    this.toasters = new ToastersStore(this);
    this.transfers = new TransfersStore(this);
    this.ui = new UiStore(this);
    this.users = new UsersStore(this);
    this.alerts = new OperationCenterStore(this);

    window.addEventListener(
      'swFreshContentReady',
      this.handleSWFreshContentReady,
      true
    );
    window.addEventListener('swCacheReady', this.handleSWCacheReady, true);
  }

  @action
  resetStores = () => {
    return new Promise((resolve) => {
      this.companies.resetParams();
      this.configurations.resetParams();
      this.contracts.resetParams();
      this.control_unit_types.resetParams();
      this.control_units.resetParams();
      this.hammer_types.resetParams();
      this.hammers.resetParams();
      this.jeanlutz.resetParams();
      this.powerpack_types.resetParams();
      this.powerpacks.resetParams();
      this.projects.resetParams();
      this.users.resetParams();
      this.erp.resetParams();
      resolve();
    });
  };

  @observable
  freshContentReady = false;

  @computed
  get freshContentIsAvailable() {
    console.log('this.freshContentReady', this.freshContentReady);
    return this.freshContentReady;
  }

  @action
  handleSWFreshContentReady = (event) => {
    console.group('handleSWFreshContentReady');
    console.log('Event', event);
    console.groupEnd();

    runInAction(() => {
      this.freshContentReady = true;
    });
  };

  @action
  handleSWCacheReady = (event) => {
    console.group('handleSWCacheReady');
    console.log('Event', event);
    console.groupEnd();

    this.toasters.add({
      variant: 'info',
      title: 'Cache is ready',
      description: 'Resources and content are cached for offline use.',
    });
  };
}

export default Store;
