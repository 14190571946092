// Imports => Vendor
import axios from 'axios';

// Imports => MOBX
import { makeObservable, toJS, observable, computed, action } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
  AcSanitize,
  AcAutoLoad,
  AcAutoSave,
  AcSaveState,
  AcRemoveState,
  AcIsSet,
  AcIsNull,
  AcFormatErrorMessage,
  AcFormatErrorCode,
  AcIsUndefined,
  AcFormatRequestParameters,
  AcFormatRawDataAsList,
  AcDownloadFile,
} from '@utils';

const _default = {
  options: {},
  equipment: null,
};

let app = {};

export class ReportsStore {
  constructor(store) {
    makeObservable(this);

    app.store = store;
  }

  @observable
  loading = {
    state: false,
    message: null,
  };

  @computed
  get is_loading() {
    return this.loading.state;
  }

  @action
  setLoading = (state = false, message = null) => {
    this.loading = {
      state,
      message,
    };
  };

  @observable
  busy = {
    state: false,
    message: null,
  };

  @computed
  get is_busy() {
    return this.busy.state;
  }

  @action
  setBusy = (state = false, message = null) => {
    this.busy = {
      state,
      message,
    };
  };

  @action
  download = async ({ id, pile_id, name, entity }, options = {}, object) => {
    let extension = 'txt';
    let mime = 'application/octet-stream';
    let hash = id;

    if (object?.[0]?.key === 'pile_id') {
      hash = object?.[0]?.value;
    }

    switch (entity) {
      case 'pdf-record':
      case 'pdf':
        mime = 'application/pdf';
        extension = 'pdf';
        break;

      case 'csv-record':
      case 'csv':
        mime = 'text/csv';
        extension = 'csv';
        break;

      case 'xls-record':
      case 'xls':
        mime = 'application/vnd.ms-excel';
        extension = 'xls';
        break;

      case 'xlsx-record':
      case 'xlsx':
      case 'excel-record':
      case 'excel':
        mime =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        extension = 'xlsx';
        break;

      default:
    }

    const filename = `miqip-report-${hash}.${extension}`;

    const params = AcFormatRequestParameters(this, options);

    const toast = await app.store.toasters.add({
      variant: 'download',
      title: 'Preparing download',
      description: `File <strong>${filename}</strong> has been requested`,
      indeterminate: true,
    });

    return app.store.api.reports
      .download(id, params)
      .then((response) => {
        AcDownloadFile(response, filename, mime);

        app.store.toasters.update(toast.id, {
          title: 'Download is complete',
          description: `File <strong>${filename}</strong> has been downloaded`,
          variant: 'success',
          indeterminate: false,
        });

        return response;
      })
      .catch((error) => {
        app.store.toasters.update(toast.id, {
          title: null,
          description: `Failed to download file <strong>${filename}</strong>`,
          code: AcFormatErrorCode(error),
          variant: 'error',
          indeterminate: false,
        });

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  pdfLink = async (hash) => {
    return app.store.api.reports.pdfLink(hash).then((response) => {
      return response;
    });
  };

  @action
  set = (target, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (AcIsUndefined(value)) return;

    this[target] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  setState = (target, property, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (!AcIsSet(property)) return;
    if (AcIsUndefined(value)) return;

    this[target][property] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  reset = (target, save = true) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;

    return new Promise((resolve) => {
      this[target] = _default[target];
      if (save && AcIsNull(_default[target])) {
        AcRemoveState(target);
      } else if (save) {
        AcSaveState(target, _default[target]);
      }

      resolve();
    });
  };
}

export default ReportsStore;
